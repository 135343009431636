var layout = {
	footer: $('footer'),
	main: $('main'),
	stage: $('div.stage'),

	init: function () {
		this.setStage();
		this.setMain();
		this.setFooter();
	},

	setFooter: function () {
		if($(window).width()>768) {
			this.footer.css('margin-left', '-'+(this.footer.width()/2)+'px');
		} else {
			this.footer.css('margin-left', '');
		}
	},

	setMain: function() {
		if($(window).width()>768) {
			this.main.css('margin-left', '-' + (this.main.width() / 2) + 'px');
		}
		else {
			this.main.css('margin-left', '');
		}

		if(this.main.width()<=1150) {

		}
	},

	setStage: function() {
		if($(window).width()>768) {
			this.stage.css('margin-left', '-'+(this.stage.width()/2)+'px');
		}
		else {
			this.stage.css('margin-left', '');
		}

		/*if(this.stage.width()>$(window).width()) {
			this.stage.width($(window).width());
		}*/
	}
};

$(function() {
	layout.init();

	$('#mobileNavToggle').click(function() {
		$(this).next('ul').slideToggle({duration:200, easing: 'swing'});
	});
	$('#mobileNavClose').click(function() {
		$(this).parent('ul').slideToggle({duration:200, easing: 'swing'});
	});

	$('nav>ul>li').click(function() {
		$('nav>ul>li').removeClass('active');
		$(this).addClass('active');
	});

	if($(".datenschutz").length > 0){
        $(".footer").addClass('privacy-footer-mobile');
	}

	if($('.gallery_previews').length>0) {
		$('.gallery_previews>li>a').click(function() {
			var newimg = $(this);
			$('div.gallery_stage img').fadeOut(200, function() {
				$(this).attr('src', $(newimg).attr('stagesrc'));
				$('.event-time').html($(newimg).attr('stagedate'));
				$('#img-title').html($(newimg).attr('stagetitle'));
				$('#img-link').attr('href', $(newimg).attr('stagelink'));
				$('.imgtitle').html($(newimg).attr('imgtitle'));

				$(this).fadeIn(200);
				var newele = $(this);
				window.setTimeout(function() {
					//$(newele).css('margin-left', $(newele).width()/2*-1);
					//$(newele).css('margin-top', $(newele).height()/2*-1);
					if ($(window).width() > 768) {
					   $('.frame').hide();
					   $( ".close" ).show();
					   $('.quote_stage').hide();
					}
					else {
					}
				}, 5);
				$('p.description').show();
			});
		});
		$('.close').click(function() {
			var newimg = $(this);
			$('div.gallery_stage img').fadeOut(200, function() {
				$(this).attr('src', $(newimg).attr('stagesrc'));
				$(this).fadeOut(200);
				var newele = $(this);
				window.setTimeout(function() {
					//$(newele).css('margin-left', $(newele).width()/2*-1);
					//$(newele).css('margin-top', $(newele).height()/2*-1);
					$( ".close" ).hide();
					$('.frame').show();
					$('.quote_stage').show();
				}, 5);
				$('p.description').hide();
			});
		});

		$('.gallery_previews>span>a:last-child').click(function() {
			var page = $('.gallery_previews>span>span');
			var show = $('.gallery_previews>li.show');
			var newShow = show.last().nextAll('li').slice(0,6);

			if(newShow.length>0) {
				show.removeClass('show');
				newShow.addClass('show');

				var newnum = parseInt(page.html())+1;
				page.html(newnum.toString());

				$('.gallery_previews>span>a:first-child').addClass('clickable');
				if(newShow.last().nextAll('li').length==0) {
					$('.gallery_previews>span>a:last-child').removeClass('clickable');
				}
			}
		});

		$('.gallery_previews>span>a:first-child').click(function() {
			var page = $('.gallery_previews>span>span');
			var show = $('.gallery_previews>li.show');
			var newShow = show.first().prevAll('li').slice(0,6);

			if(newShow.length>0) {
				show.removeClass('show');
				newShow.addClass('show');

				var newnum = parseInt(page.html())-1;
				page.html(newnum.toString());

				$('.gallery_previews>span>a:last-child').addClass('clickable');

				if($('.gallery_previews>li.show').first().prevAll('li').length==0) {
					$('.gallery_previews>span>a:first-child').removeClass('clickable');
				}
			}
		});
	}
});

$( window ).resize(function() {
	layout.init();

	if($(window).width()>768) {
		$('nav ul').css('display', '');
	}
});